exports.components = {
  "component---contentcurator-gatsby-template-src-pages-404-js": () => import("./../../../../contentcurator-gatsby-template/src/pages/404.js" /* webpackChunkName: "component---contentcurator-gatsby-template-src-pages-404-js" */),
  "component---contentcurator-gatsby-template-src-pages-about-js": () => import("./../../../../contentcurator-gatsby-template/src/pages/about.js" /* webpackChunkName: "component---contentcurator-gatsby-template-src-pages-about-js" */),
  "component---contentcurator-gatsby-template-src-pages-contact-js": () => import("./../../../../contentcurator-gatsby-template/src/pages/contact.js" /* webpackChunkName: "component---contentcurator-gatsby-template-src-pages-contact-js" */),
  "component---contentcurator-gatsby-template-src-pages-disclosure-js": () => import("./../../../../contentcurator-gatsby-template/src/pages/disclosure.js" /* webpackChunkName: "component---contentcurator-gatsby-template-src-pages-disclosure-js" */),
  "component---contentcurator-gatsby-template-src-pages-index-js": () => import("./../../../../contentcurator-gatsby-template/src/pages/index.js" /* webpackChunkName: "component---contentcurator-gatsby-template-src-pages-index-js" */),
  "component---contentcurator-gatsby-template-src-pages-privacy-js": () => import("./../../../../contentcurator-gatsby-template/src/pages/privacy.js" /* webpackChunkName: "component---contentcurator-gatsby-template-src-pages-privacy-js" */),
  "component---contentcurator-gatsby-template-src-templates-entity-js": () => import("./../../../../contentcurator-gatsby-template/src/templates/entity.js" /* webpackChunkName: "component---contentcurator-gatsby-template-src-templates-entity-js" */),
  "component---contentcurator-gatsby-template-src-templates-latest-template-js": () => import("./../../../../contentcurator-gatsby-template/src/templates/latest_template.js" /* webpackChunkName: "component---contentcurator-gatsby-template-src-templates-latest-template-js" */),
  "component---contentcurator-gatsby-template-src-templates-page-js": () => import("./../../../../contentcurator-gatsby-template/src/templates/page.js" /* webpackChunkName: "component---contentcurator-gatsby-template-src-templates-page-js" */)
}

